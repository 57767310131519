.app {
  height: 100%;
  background-image: url('/public/background.jpeg');
  background-size: cover;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #fff;
  padding: 10px 200px;
  transition: all 200ms ease-in-out;
}

.header div {
  display: flex;
  align-items: center;
}

.header img {
  height: 50px;
  margin-right: 20px;
  transition: all 200ms ease-in-out;
}

.header ul {
  display: flex;
  list-style: none;
}

.header li a {
  color: #fff;
  margin-right: 15px;
  text-decoration: none;
  font-weight: bold;
  transition: all 200ms ease-in-out;
  font-size: 12px;
  text-transform: uppercase;
}

.home-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.content-page {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.content-page .content {
  text-align: center;
}

.content-page h1 {
  margin: 0;
  color: yellow;
}

.content-page h2 {
  margin: 0;
  color: yellow;
}

.content-page button {
  background-color: red;
  color: #fff;
  padding: 10px 20px;
  margin-top: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.content-page button:hover {
  background-color: #ff4d4d;
}

.toRotate {
  transform: scaleX(-1); /* flip horizontally */
}

@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
  }
  nav a {
    font-size: 12px;
  }
  .content-title {
    font-size: 20px;
    transition: all 200ms ease-in-out;
  }
  .content-subtitle {
    font-size: 15px;
    transition: all 200ms ease-in-out;
  }
  .img-showcase {
    width: 200px;
    transition: all 200ms ease-in-out;
  }
  .left-side img, .right-side img {
    width: 250px;
    transition: all 200ms ease-in-out;
  }
}
.text-support {
  color: yellow
}
.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-group__button {
  background-color: #ff4d4d;
  border: none;
  padding: 10px;
  font-size: 1.2em;
  cursor: pointer;
}

.input-group__input {
  text-align: center;
  border: 3px solid #ff4d4d;
  padding: 10px;
  margin-top: 10px;
}

@media (max-width: 700px) {
  .header {
    padding: 10px;
  }
  nav a {
    font-size: 10px;
  }
  .content-title {
    font-size: 20px;
    transition: all 200ms ease-in-out;
  }
  .content-subtitle {
    font-size: 15px;
    transition: all 200ms ease-in-out;
  }
  .img-showcase {
    width: 150px;
    transition: all 200ms ease-in-out;
  }
  .left-side img, .right-side img {
    display: none;
    transition: all 200ms ease-in-out;
  }
}